<template>
  <v-card class="match-card mb-3">
    <div class="match-container">
      <!-- Main Match Content -->
      <div
          :class="['match-content', {'match-content--clickable': match.result}]"
          @click="handleMatchClick"
      >
        <!-- Team 1 -->
        <div class="team-container team-container--right">
          <div v-if="isTennisWinner(1)" class="winner-container">
            <v-btn rounded="xl" color="success" variant="flat" class="winner-btn">
              <span class="winner-text">Ganador<br>{{ match.name_team_1 }}</span>
            </v-btn>
          </div>
          <span v-else class="team-name">{{ match.name_team_1 }}</span>
        </div>

        <!-- Score/VS -->
        <div class="score-container">
          <span v-if="match.result && tournamentDetails.isFootball" class="score-display">
            {{ formattedScore }}
          </span>
          <span v-else class="vs-text">vs</span>
        </div>

        <!-- Team 2 -->
        <div class="team-container team-container--left">
          <div v-if="isTennisWinner(2)" class="winner-container">
            <v-btn rounded="xl" color="success" variant="flat" class="winner-btn">
              <span class="winner-text">Ganador<br>{{ match.name_team_2 }}</span>
            </v-btn>
          </div>
          <span v-else class="team-name">{{ match.name_team_2 }}</span>
        </div>
      </div>

      <!-- Match Actions -->
      <div v-if="showActions" class="match-actions">
        <template v-if="!match.result && isAble">
          <v-btn
              color="primary"
              variant="outlined"
              size="small"
              class="action-btn"
              @click.stop="handlePlanilla"
          >
            Abrir planilla
          </v-btn>
          <v-btn
              color="primary"
              variant="tonal"
              size="small"
              class="action-btn"
              :to="matchResultRoute"
              @click.stop
          >
            Cargar partido
          </v-btn>
        </template>
        <template v-else-if="match.result && isAble">
          <v-btn
              color="error"
              variant="outlined"
              size="small"
              class="action-btn"
              @click.stop="confirmDelete"
          >
            Borrar resultado
          </v-btn>
        </template>
      </div>
    </div>

    <v-overlay
        v-model="isLoading"
        contained
        class="align-center justify-center"
    >
      <v-progress-circular indeterminate color="primary" />
    </v-overlay>
  </v-card>
</template>

<script setup>
/* eslint-disable */
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useMatchtore } from "@/store/match";
import { useAlertStore } from "@/models/useErrorHandling";
import {useTournamentDetailsStore} from "@/store/tournamentDetails";
//TODO chequear que el torneo 134, tiene el partido completado y no trae el campo ganador
const props = defineProps({
  match: {
    type: Object,
    required: true,
    validator(value) {
      return value.id && value.id_tournament &&
          value.name_team_1 && value.name_team_2;
    }
  },
  isAble: {
    type: Boolean,
    default: false
  },
});

const emit = defineEmits(['match-updated']);
const router = useRouter();
const matchStore = useMatchtore();
const alertStore = useAlertStore();
const isLoading = ref(false);
const tournamentDetails = useTournamentDetailsStore();

//alert(tournamentDetails.sport);

const showActions = computed(() => {
  return (props.match.name_team_1 !== 'BYE' && props.match.name_team_2 !== 'BYE') &&
      (props.match.name_team_1 !== '' && props.match.name_team_2 !== '') &&
      (props.match.name_team_1 !== 'Proximamente' && props.match.name_team_2 !== 'Proximamente') &&
      props.isAble;
});

const formattedScore = computed(() => {
  if (!props.match.result) return '';

  try {
    if (props.match.res_to_show) {
      const scores = JSON.parse(props.match.res_to_show);
      return scores.join('-');
    }
    return props.match.result.replace(/\s/g, '');
  } catch (error) {
    console.error('Error parsing score:', error);
    return props.match.result.replace(/\s/g, '');
  }
});

const matchResultRoute = computed(() => ({
  name: 'matchResult',
  params: {
    matchId: props.match.id,
    tournamentId: props.match.id_tournament
  }
}));

const isTennisWinner = (teamNumber) => {
  if (!tournamentDetails.isTennis || !props.match.winner) return false;
  return (teamNumber === 1 && props.match.id_team_1 === props.match.winner) ||
      (teamNumber === 2 && props.match.id_team_2 === props.match.winner);
};

const handleMatchClick = () => {
  if (props.match.result) {
    router.push({
      name: 'showMatch',
      params: {
        tournamentId: props.match.id_tournament,
        matchId: props.match.id
      }
    });
  }
};

const handlePlanilla = async () => {
  try {
    isLoading.value = true;
    const response = await matchStore.dispatchPdfMatch(
        props.match.id,
        props.match.id_tournament
    );

    const blob = new Blob([response.content]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `planilla_${props.match.name_team_1}_vs_${props.match.name_team_2}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    alertStore.showErrorAlert('Error al generar la planilla', error.message);
  } finally {
    isLoading.value = false;
  }
};

const confirmDelete = () => {
  alertStore.setMatchConfirmation(props.match.id, props.match.id_tournament);
  alertStore.showConfirmationAlert(
      "Eliminar resultado",
      "¿Está seguro que desea eliminar el resultado?",
      "Sí, eliminar",
      () => emit('match-updated')
  );
};
</script>

<style scoped>
.match-card {
  border-radius: 12px;
  background-color: white;
  overflow: hidden;
  position: relative;
}

.match-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.match-content {
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 60px;
  padding: 0.5rem;
  transition: all 0.3s ease;
  margin-right: 1rem;
}

.match-content--clickable {
  cursor: pointer;
}

.match-content--clickable:hover {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.team-container {
  flex: 1;
  display: flex;
  align-items: center;
  min-height: 60px;
}

.team-container--right {
  justify-content: flex-end;
  padding-right: 1.5rem;
}

.team-container--left {
  justify-content: flex-start;
  padding-left: 1.5rem;
}

.winner-container {
  display: flex;
  align-items: center;
}

.winner-btn {
  text-transform: none !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
}

.winner-text {
  font-size: 0.9rem;
  line-height: 1.2;
  text-align: center;
}

.score-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  min-width: 120px;
}

.score-display {
  font-weight: 700;
  color: black;
  font-size: 1.2rem;
  background-color: #20D19A;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  display: inline-block;
}

.vs-text {
  color: #78909C;
  font-weight: 500;
  font-size: 1rem;
}

.team-name {
  font-size: 1.1rem;
  font-weight: 500;
  color: #37474F;
}

.match-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 140px;
  justify-content: center;
  align-items: stretch;
}

.action-btn {
  text-transform: none !important;
  letter-spacing: 0 !important;
  font-weight: 500 !important;
}

@media (max-width: 960px) {
  .match-container {
    flex-direction: column;
    padding: 0.5rem;
  }

  .match-content {
    margin-right: 0;
    margin-bottom: 0.5rem;
    width: 100%;
  }

  .match-actions {
    flex-direction: row;
    width: 100%;
    padding: 0.5rem;
    border-top: 1px solid #E0E0E0;
    min-width: unset;
  }

  .team-container {
    min-height: 50px;
  }

  .team-container--right {
    padding-right: 0.8rem;
  }

  .team-container--left {
    padding-left: 0.8rem;
  }

  .team-name {
    font-size: 0.9rem;
  }

  .score-container {
    width: 80px;
    min-width: 80px;
  }

  .score-display {
    font-size: 1rem;
    padding: 0.2rem 0.5rem;
  }

  .action-btn {
    flex: 1;
    font-size: 0.8rem;
  }

  .winner-btn {
    padding: 4px 8px !important;
  }

  .winner-text {
    font-size: 0.8rem;
  }

  .vs-text {
    font-size: 0.9rem;
  }
}

/* Additional responsive adjustments for very small screens */
@media (max-width: 480px) {
  .match-content {
    padding: 0.25rem;
  }

  .team-container {
    min-height: 40px;
  }

  .team-container--right {
    padding-right: 0.5rem;
  }

  .team-container--left {
    padding-left: 0.5rem;
  }

  .team-name {
    font-size: 0.8rem;
  }

  .score-container {
    width: 60px;
    min-width: 60px;
  }

  .score-display {
    font-size: 0.9rem;
    padding: 0.2rem 0.4rem;
  }

  .match-actions {
    padding: 0.25rem;
    gap: 0.25rem;
  }

  .action-btn {
    font-size: 0.75rem;
    height: 28px !important;
  }
}
</style>